<template>
  <div class="modal fade" tabindex="-10" id="modal_pagamento_partecipante">
    <div class="modal-dialog modal-lg">
      <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :z-index="1100"
      ></loading>
      <Form
        class="form"
        @submit="onSubmit"
        :validation-schema="schema"
        @invalid-submit="onInvalidSubmit"
      >
        <div class="modal-content" v-if="pagamentoIsLoaded">
          <div class="modal-header">
            <h5 class="modal-title">
              Inserisci pagamento iscrizione di {{ selectedPersona }}
            </h5>

            <!--begin::Close-->
            <div
              class="btn btn-icon btn-sm btn-active-secondary"
              style="border: 0; !important"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="bi bi-x-lg"> </i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body">
            <div class="card-body p-6">
              <div class="ps-5 pe-5">
                <div
                  class="alert alert-danger"
                  role="alert"
                  v-if="alertCampiObb"
                >
                  Compila i campi obbligatori
                </div>
                <div class="row pb-4">
                  <div class="col-md-3 filter">
                    <label class="fw-bold text-gray-600">Stagione*</label>
                    <input
                      type="text"
                      class="form-control"
                      :value="corsoStagione"
                      disabled
                    />
                  </div>
                  <div class="col-md-3">
                    <label class="fw-bold text-gray-600">Importo*</label>
                    <input
                      type="text"
                      class="form-control"
                      :value="importoPagamento.toFixed(2) + ' €'"
                      disabled
                    />
                  </div>
                  <div class="col-md-3 filter">
                    <label class="fw-bold text-gray-600">Tipo pagamento*</label>
                    <input
                      type="text"
                      class="form-control"
                      value="Bonifico"
                      disabled
                    />
                  </div>
                  <div class="col-md-3">
                    <label class="fw-bold text-gray-600">Data pagamento*</label>
                    <button class="bugSolver"></button>
                    <Datepicker
                      v-model="data_Competenza"
                      @update:modelValue="
                        $event
                          ? (data_Competenza = fromUTCtoISODate($event))
                          : ''
                      "
                      placeholder=""
                      :format="format"
                      :monthChangeOnScroll="false"
                      :enableTimePicker="false"
                      inputClassName="h-28"
                      autoApply
                    />
                    <Field
                      name="data_Competenza"
                      type="hidden"
                      class="form-control"
                      v-model="data_Competenza"
                    />
                    <ErrorMessage
                      class="help-message"
                      style="color: red"
                      name="data_Competenza"
                    />
                  </div>
                </div>
                <div class="row pb-4">
                  <div class="col-md-6">
                    <label class="fw-bold text-gray-600">Banca/Agenzia*</label>
                    <Field
                      name="istituto"
                      type="text"
                      v-model="istituto"
                      class="form-control"
                    />
                    <ErrorMessage
                      class="help-message"
                      style="color: red"
                      name="istituto"
                    />
                  </div>
                  <div class="col-md-6">
                    <label class="fw-bold text-gray-600">Riferimenti*</label>
                    <Field
                      name="riferimenti"
                      type="text"
                      class="form-control"
                      v-model="riferimenti"
                    />
                    <ErrorMessage
                      class="help-message"
                      style="color: red"
                      name="riferimenti"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="me-3 badge bg-light-danger rounded text-danger fs-6"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="closeModalPagamento"
            >
              Chiudi
            </button>
            <button
              type="button"
              class="badge bg-danger rounded fs-6"
              @click="resetField"
            >
              Reset
            </button>
            <button
              type="submit"
              class="badge bg-secondary rounded blueFit fs-6"
              :disabled="disableButton"
            >
              Conferma
            </button>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { defineComponent, getCurrentInstance, computed, ref } from "vue";
import { Form, ErrorMessage, Field } from "vee-validate";
import * as yup from "yup";
import { useStore } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Datepicker from "vue3-date-time-picker";
import { format } from "@/composables/formatDate";
import { fromUTCtoISODate } from "@/composables/formatDate";
import "vue3-date-time-picker/dist/main.css";
import { setPagamentoPartecipanteCorso } from "@/requests/snmCorsi";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

export default defineComponent({
  name: "add-partecipante-pagamento",
  components: { Loading, Datepicker, Form, ErrorMessage, Field },
  emits: ["changeStatoPagato"],
  props: {
    selectedIdPartecipante: {
      type: Number,
      required: true,
    },
    selectedIdPersona: {
      type: Number,
      required: true,
    },
    selectedPersona: {
      type: String,
      required: true,
    },
    importoPagamento: {
      type: Number,
      required: true,
    },
    corsoStagione: {
      type: Number,
      required: true,
    },
    id_corso: {
      type: Number,
      required: true,
    },
    pagamentoIsLoaded: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const pagamento_tipo = computed(() =>
      store.getters.getStateFromName("pagamento_tipo")
    );

    const keys = ref("pt");
    if (!pagamento_tipo.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    //const selectedStagione = props.corsoStagione;

    const alertCampiObb = ref(false);
    const isLoaded = ref(true);
    const defaultpagamento = ref("01");
    const data_Competenza = ref(null);
    const istituto = ref("");
    const riferimenti = ref("");
    const id_Tipo_Pagamento = ref(4);

    const schema = yup.object().shape({
      istituto: yup.string().required("Obbligatorio"),
      riferimenti: yup.string().required("Obbligatorio"),
      data_Competenza: yup
        .string()
        .required("Obbligatorio")
        .nullable()
        .typeError("Obbligatorio"),
    });

    const disableButton = ref(false);
    const isLoading = ref(false);

    const onSubmit = (values) => {
      disableButton.value = true;
      isLoading.value = true;
      alertCampiObb.value = false;
      values.id_iscrizione = props.selectedIdPartecipante;
      values.id_corso = props.id_corso;
      values.id_persona = props.selectedIdPersona;
      values.descrizione = istituto.value + " - " + riferimenti.value;
      const responseAdd = setPagamentoPartecipanteCorso(values);
      responseAdd.then((value) => {
        if (value.status == 200) {
          Swal.fire({
            html: "Pagamento inserito con successo",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
          resetField();
          document.getElementById("closeModalPagamento").click();
          emit("changeStatoPagato", props.selectedIdPartecipante);
        } else {
          const strMsg = ref(
            value.data.message
              ? value.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
          Swal.fire({
            html: strMsg.value,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        }
        disableButton.value = false;
        isLoading.value = false;
      });
    };

    const onInvalidSubmit = (v) => {
      alertCampiObb.value = true;
    };

    const resetField = () => {
      alertCampiObb.value = false;
      istituto.value = "";
      riferimenti.value = "";
      data_Competenza.value = null;
    };

    return {
      isLoaded,
      //selectedStagione,
      stagioni: computed(() => store.getters.stagioni),
      alertCampiObb,
      schema,
      onSubmit,
      onInvalidSubmit,
      resetField,
      pagamento_tipo,
      defaultpagamento,
      istituto,
      riferimenti,
      data_Competenza,
      format,
      id_Tipo_Pagamento,
      disableButton,
      isLoading,
      fromUTCtoISODate,
    };
  },
});
</script>

<style scoped>
.modal-content {
  width: 140%;
}

.modal-body {
  padding: 0px;
}

.modal-dialog {
  margin-top: 3rem;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
  }
}

@media (max-width: 767.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: translate(5%, -50%);
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 0px;
  width: 0px;
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: none;
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 28px;
  width: 28px;
}

.image-input [data-kt-image-input-action="change"] {
  left: 0%;
  top: 0%;
}
</style>
