<template>
  <div>
    <div class="card shadow">
      <form autocomplete="off">
        <div
          class="pt-4 pb-4 pe-4 ps-4"
          @keyup.enter="$emit('getCorsoPartecipantiList')"
        >
          <div class="row pb-4">
            <div class="col-sm-3 filter">
              <SelectInput
                :options="trueFalseLookup"
                name="trueFalseLookup"
                placeholder="Idoneo"
                :value="idoneoCorsoPartecipanti"
                @changeSelect="setIdoneoCorsoPartecipanti($event)"
              />
            </div>
            <div class="col-sm-3 filter">
              <SelectInput
                :options="trueFalseLookup"
                name="trueFalseLookup"
                placeholder="Scontato"
                :value="scontatoCorsoPartecipanti"
                @changeSelect="setScontatoCorsoPartecipanti($event)"
              />
            </div>
            <div class="col-sm-3 filter">
              <SelectInput
                :options="trueFalseLookup"
                name="trueFalseLookup"
                placeholder="Pagato"
                :value="pagatoCorsoPartecipanti"
                @changeSelect="setPagatoCorsoPartecipanti($event)"
              />
            </div>
            <div class="col-sm-3 filter">
              <SelectInput
                :options="trueFalseLookup"
                name="trueFalseLookup"
                placeholder="Approvato"
                :value="approvatoCorsoPartecipanti"
                @changeSelect="setApprovatoCorsoPartecipanti($event)"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-3 filter">
              <SelectInput
                :options="trueFalseLookup"
                name="trueFalseLookup"
                placeholder="Presente"
                :value="presenteCorsoPartecipanti"
                @changeSelect="setPresenteCorsoPartecipanti($event)"
              />
            </div>
            <div class="col-sm-5 filter">
              <div class="dp__input_wrap text-gray-600">
                <input
                  placeholder="Tesserato"
                  name="nome_tesserato"
                  type="text"
                  class="form-control"
                  @input="
                    getPersone($event.target.value);
                    inputFocus = 'persona';
                  "
                  :value="nomePersonaCorsoPartecipanti"
                  autocomplete="off"
                />
                <svg
                  @click="
                    setIdPersonaCorsoPartecipanti({ name: null, value: null })
                  "
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  class="dp__icon dp__clear_icon dp__input_icons"
                >
                  <path
                    d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                  ></path>
                  <path
                    d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                  ></path>
                </svg>
              </div>
              <ListTesserati
                :list="tesserati"
                v-if="tesserati && inputFocus === 'persona'"
                @hideList="
                  resetTesserati();
                  inputFocus = null;
                "
                @selectedTesserato="
                  resetTesserati();
                  setIdPersonaCorsoPartecipanti($event);
                  inputFocus = null;
                "
              ></ListTesserati>
            </div>
          </div>
          <div class="text-center mt-5">
            <button
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600"
              @click="$emit('getCorsoPartecipantiList')"
              :disabled="!loaded"
            >
              Cerca <i class="bi bi-search fs-6 text-gray-600"> </i>
            </button>
            <span
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click="$emit('resetFilters')"
            >
              <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
            ></span>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref } from "vue";
import { useStore } from "vuex";
import ListTesserati from "@/components/components-fit/utility/ListTesserati.vue";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";

export default defineComponent({
  name: "filtri-iscritti-corso",
  emits: ["resetFilters", "getCorsoPartecipantiList"],
  props: {
    campiLocked: {
      type: String,
      required: true,
    },
  },
  components: {
    ListTesserati,
    SelectInput,
  },
  setup(props, { emit }) {
    const store = useStore();

    const nomePersonaCorsoPartecipanti = computed(
      () => store.getters.nomePersonaCorsoPartecipanti
    );

    const idoneoCorsoPartecipanti = computed(
      () => store.getters.idoneoCorsoPartecipanti
    );

    const pagatoCorsoPartecipanti = computed(
      () => store.getters.pagatoCorsoPartecipanti
    );

    const presenteCorsoPartecipanti = computed(
      () => store.getters.presenteCorsoPartecipanti
    );

    const approvatoCorsoPartecipanti = computed(
      () => store.getters.approvatoCorsoPartecipanti
    );

    const scontatoCorsoPartecipanti = computed(
      () => store.getters.scontatoCorsoPartecipanti
    );

    const idPersonaCorsoPartecipanti = computed(
      () => store.getters.idPersonaCorsoPartecipanti
    );

    const setIdoneoCorsoPartecipanti = (id) => {
      store.commit("setIdoneoCorsoPartecipanti", id);
    };

    const setScontatoCorsoPartecipanti = (id) => {
      store.commit("setScontatoCorsoPartecipanti", id);
    };

    const setPagatoCorsoPartecipanti = (id) => {
      store.commit("setPagatoCorsoPartecipanti", id);
    };

    const setPresenteCorsoPartecipanti = (id) => {
      store.commit("setPresenteCorsoPartecipanti", id);
    };

    const setApprovatoCorsoPartecipanti = (id) => {
      store.commit("setApprovatoCorsoPartecipanti", id);
    };

    const setIdPersonaCorsoPartecipanti = ({ value, name }) => {
      store.commit("setIdPersonaCorsoPartecipanti", value);
      store.commit("setNomePersonaCorsoPartecipanti", name);
    };

    const getPersone = (string) => {
      store.commit("setNomePersonaCorsoPartecipanti", string);
      if (string === "") {
        store.dispatch("svuotaPersoneNomeCognome");
        return;
      } else if (string.length < 3) return;
      store.dispatch("getPersoneNomeCognome", string);
    };
    const resetTesserati = () => {
      store.dispatch("svuotaPersoneNomeCognome");
    };

    const inputFocus = ref("");

    return {
      getPersone,
      resetTesserati,
      trueFalseLookup: [
        { id: true, label: "Si" },
        { id: false, label: "No" },
      ],
      tesserati: computed(() => store.getters.personeSocieta),
      setIdoneoCorsoPartecipanti,
      setScontatoCorsoPartecipanti,
      setPagatoCorsoPartecipanti,
      setPresenteCorsoPartecipanti,
      setApprovatoCorsoPartecipanti,
      setIdPersonaCorsoPartecipanti,
      nomePersonaCorsoPartecipanti,
      idoneoCorsoPartecipanti,
      pagatoCorsoPartecipanti,
      presenteCorsoPartecipanti,
      scontatoCorsoPartecipanti,
      approvatoCorsoPartecipanti,
      idPersonaCorsoPartecipanti,
      inputFocus,
      loaded: computed(() =>
        store.getters.getStateFromName("loadedcorso_partecipanti_list")
      ),
    };
  },
});
</script>

<style></style>
