<template>
  <div>
    <DetailCorso
      :id_Corso="id_Corso"
      @setStagioneCorso="
        stagioneCorso = $event.stagione;
        id_tipo_corso = $event.id_tipo_corso;
      "
    />
    <FilterIscrittiCorso
      @getCorsoPartecipantiList="searchCorsoPartecipantiList"
      @resetFilters="resetFilters"
    />
    <TableIscrittiCorso
      @getCorsoPartecipantiList="getCorsoPartecipantiList"
      @resetFilters="resetFilters"
      :id_Corso="id_Corso"
      :stagioneCorso="stagioneCorso"
      :id_tipo_corso="id_tipo_corso"
    />
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  computed,
  getCurrentInstance,
  ref,
} from "vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

import { useRoute } from "vue-router";

import DetailCorso from "@/components/components-fit/SNM/gestione-corsi/DetailCorso.vue";
import FilterIscrittiCorso from "@/components/components-fit/SNM/gestione-corsi/FiltriIscrittiCorso.vue";
import TableIscrittiCorso from "@/components/components-fit/SNM/gestione-corsi/TableIscrittiCorso.vue";

export default defineComponent({
  name: "IscrittiCorso",
  components: { DetailCorso, FilterIscrittiCorso, TableIscrittiCorso },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Iscritti Corso", ["Lista Corsi"]);
    });
    const route = useRoute();
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const rowsToSkip = computed(
      () => store.getters.rowsToSkipCorsoPartecipanti
    );
    const fetchRows = computed(() => store.getters.fetchRowsCorsoPartecipanti);
    const sortColumn = computed(
      () => store.getters.sortColumnCorsoPartecipanti
    );
    const sortOrder = computed(() => store.getters.sortOrderCorsoPartecipanti);

    const id_Corso = ref(route.params.id);
    const id_Persona = computed(() => store.getters.idPersonaCorsoPartecipanti);
    const idoneo = computed(() => store.getters.idoneoCorsoPartecipanti);
    const scontato = computed(() => store.getters.scontatoCorsoPartecipanti);
    const approvato = computed(() => store.getters.approvatoCorsoPartecipanti);
    const pagato = computed(() => store.getters.pagatoCorsoPartecipanti);
    const presente = computed(() => store.getters.presenteCorsoPartecipanti);

    const getCorsoPartecipantiList = () => {
      store.dispatch("setStoreListData", {
        keys: {
          id_Corso: id_Corso.value,
          id_Persona: id_Persona.value,
          idoneo: idoneo.value,
          scontato: scontato.value,
          approvato: approvato.value,
          pagato: pagato.value,
          presente: presente.value,
          rowstoskip: rowsToSkip.value,
          fetchrows: fetchRows.value,
          sortcolumn: sortColumn.value,
          sortorder: sortOrder.value,
        },
        apiLink: globalApi.CORSO_PARTECIPANTI_LIST,
        itemName: "corso_partecipanti_list",
      });
    };

    const resetFilters = () => {
      store.commit("resetFiltersCorsoPartecipanti");
      getCorsoPartecipantiList();
    };
    const loaded = computed(() =>
      store.getters.getStateFromName("loadedcorso_partecipanti_list")
    );

    const searchCorsoPartecipantiList = () => {
      if (!loaded.value) return;
      store.commit("setRowsToSkipCorsoPartecipanti");
      getCorsoPartecipantiList();
    };

    getCorsoPartecipantiList();

    const stagioneCorso = ref(null);
    const id_tipo_corso = ref(null);

    return {
      id_tipo_corso,
      stagioneCorso,
      id_Corso,
      getCorsoPartecipantiList,
      resetFilters,
      searchCorsoPartecipantiList,
    };
  },
});
</script>
