<template>
  <div class="modal fade" tabindex="-10" id="kt_modal_add_partecipante">
    <div class="modal-dialog">
      <loading
        :active="isLoading"
        :is-full-page="fullPage"
        :z-index="1100"
      ></loading>
      <Form
        class="form"
        @submit="onSubmit"
        :validation-schema="schema"
        @invalid-submit="onInvalidSubmit"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Nuovo partecipante</h5>

            <!--begin::Close-->
            <div
              class="btn btn-icon btn-sm btn-active-secondary"
              style="border: 0; !important"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="bi bi-x-lg"> </i>
            </div>
            <!--end::Close-->
          </div>

          <div class="modal-body">
            <div class="card-body">
              <form autocomplete="off">
                <div
                  class="alert alert-danger"
                  role="alert"
                  v-if="alertCampiObb"
                >
                  Compila i campi obbligatori
                </div>
                <input
                  placeholder="Persona"
                  name="nome_persona"
                  type="text"
                  class="form-control"
                  @input="
                    getTesserati($event.target.value);
                    nome_persona === ''
                      ? (id_Persona = null)
                      : (id_Persona = 0);
                    inputFocus = 3;
                  "
                  v-model="nome_persona"
                  autocomplete="off"
                />
                <Field
                  name="id_Persona"
                  type="hidden"
                  v-model="id_Persona"
                  class="form-control"
                />
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  class="dp__icon dp__clear_icon dp__input_icons"
                  @click="spliceTesserati()"
                >
                  <path
                    d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                  ></path>
                  <path
                    d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                  ></path>
                </svg>

                <ListTesserati
                  :list="tesseratiList"
                  v-if="tesseratiList && inputFocus === 3"
                  @hideList="
                    resetTesseratiList();
                    inputFocus = null;
                  "
                  @selectedTesserato="
                    resetTesseratiList();
                    nome_persona = $event.name;
                    id_Persona = $event.value;
                    inputFocus = null;
                  "
                ></ListTesserati>
                <ErrorMessage
                  class="help-message"
                  style="color: red"
                  name="id_Persona"
                />
                <div class="p-5 fw-bold">
                  <div class="form-check row pb-4">
                    <TextInput
                      name="sconto"
                      type="checkbox"
                      :checked="sconto"
                      :value="sconto ? (sconto = true) : (sconto = false)"
                      @change="sconto = !sconto"
                    />
                    <label class="form-check-label" for="flexCheckChecked">
                      Sconta corso
                    </label>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="me-3 badge bg-light-danger rounded text-danger fs-6"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="closeModal"
            >
              Chiudi
            </button>
            <button
              type="button"
              class="badge bg-danger rounded fs-6"
              @click="resetField"
            >
              Reset
            </button>
            <button
              type="submit"
              class="badge bg-secondary rounded blueFit fs-6"
              :disabled="disableButton"
            >
              Aggiungi
            </button>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from "vue";
import { Form, ErrorMessage, Field } from "vee-validate";
import * as yup from "yup";
import { useStore } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ListTesserati from "@/components/components-fit/utility/ListTesserati.vue";
import TextInput from "@/components/components-fit/utility/inputs/TextInput.vue";
import { addPartecipanteCorso } from "@/requests/snmCorsi";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

export default defineComponent({
  name: "AddPartecipanteCorso",
  components: { Loading, Form, ErrorMessage, Field, ListTesserati, TextInput },
  props: {
    id_Corso: {
      type: Number,
      required: true,
    },
  },
  emits: ["refreshList"],
  setup(props, { emit }) {
    const store = useStore();

    const alertCampiObb = ref(false);
    const nome_persona = ref("");
    const id_Persona = ref(null);
    const sconto = ref(false);

    const getTesserati = (string) => {
      if (string === "") {
        store.dispatch("svuotaPersoneNomeCognome");
        return;
      } else if (string.length < 3) return;
      store.dispatch("getPersoneNomeCognome", string);
    };

    const resetTesseratiList = () => {
      store.dispatch("svuotaPersoneNomeCognome");
    };

    const spliceTesserati = () => {
      nome_persona.value = "";
      id_Persona.value = 0;
      resetTesseratiList();
    };

    const schema = yup.object().shape({
      id_Persona: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio")
        .nullable()
        .moreThan(0, "Obbligatorio"),
    });

    const disableButton = ref(false);
    const isLoading = ref(false);

    const onSubmit = (values) => {
      disableButton.value = true;
      isLoading.value = true;
      values.id_Corso = props.id_Corso;
      values.sconto = sconto.value;
      alertCampiObb.value = false;
      const responseAdd = addPartecipanteCorso(values);
      responseAdd.then((value) => {
        if (value.status == 200) {
          Swal.fire({
            html: "Partecipante aggiunto al corso",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
          resetField();
          document.getElementById("closeModal").click();
          emit("refreshList");
        } else {
          const strMsg = ref(
            value.data.message
              ? value.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
          Swal.fire({
            html: strMsg.value,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        }
        disableButton.value = false;
        isLoading.value = false;
      });
    };

    const onInvalidSubmit = () => {
      alertCampiObb.value = true;
    };

    const resetField = () => {
      alertCampiObb.value = false;
      sconto.value = false;
      spliceTesserati();
    };

    const inputFocus = ref("");

    return {
      schema,
      onSubmit,
      onInvalidSubmit,
      resetField,
      alertCampiObb,
      nome_persona,
      id_Persona,
      getTesserati,
      resetTesseratiList,
      spliceTesserati,
      sconto,
      tesseratiList: computed(() => store.getters.personeSocieta),
      inputFocus,
      disableButton,
      isLoading,
    };
  },
  data() {
    return {};
  },
});
</script>

<style scoped>
.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: translate(5%, -50%);
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 0px;
  width: 0px;
}

@media (max-width: 1200px) {
  .modal-dialog {
    max-width: 55%;
  }
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 65%;
  }
}

.dp__clear_icon {
  top: 28%;
  right: 5%;
}
</style>
